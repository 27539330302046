import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { errorsVar, roleEditVar, successErrorType } from "../../cache/cache";
import { useAddRoleMutation, useUpdateRoleMutation, UpdateRoleMutationVariables } from "../../types/graphql";
import { setError } from "../../misc/common";
import { ROLES } from "../../graphql/queries";
import ManageRolePermissions from "./ManageRolePermissions";

import "./styles.css";

const Input = React.lazy(() => import("../../components/common/Input"));
const Button = React.lazy(() => import("../../components/common/Button"));

const ManageRole: React.FC = () => {
    let errors = useReactiveVar(errorsVar);

    const navigate = useNavigate();
    const location = useLocation();
    const editFields = useReactiveVar(roleEditVar);

    const [fields, setFields] = useState<UpdateRoleMutationVariables>({
        id: editFields?.id || "",
        name: editFields?.name || "",
        permissions: {
            // ...editFields?.permissions
            accessAllGroups: editFields?.permissions.accessAllGroups || false,
            accessUserPage: editFields?.permissions.accessUserPage || false,
            adminChat: editFields?.permissions.adminChat || false,
            broadcastYoutube: editFields?.permissions.broadcastYoutube || false,
            createGroup: editFields?.permissions.createGroup || false,

            createInstruction: editFields?.permissions.createInstruction || false,
            createTask: editFields?.permissions.createTask || false,
            editBalance: editFields?.permissions.editBalance || false,
            editDateMembership: editFields?.permissions.editDateMembership || false,
            editDateTraining: editFields?.permissions.editDateTraining || false,
            editPayments: editFields?.permissions.editPayments || false,

            editUserData: editFields?.permissions.editUserData || false,
            giveFreeDuels: editFields?.permissions.giveFreeDuels || false,
            organizeDuel: editFields?.permissions.organizeDuel || false,
            participateFree: editFields?.permissions.participateFree || false,
            participateFreeJudge: editFields?.permissions.participateFreeJudge || false,
            participateFreeMaster: editFields?.permissions.participateFreeMaster || false,
            participateFreePlayer: editFields?.permissions.participateFreePlayer || false,
            registerUser: editFields?.permissions.registerUser || false,

            seeTransactions: editFields?.permissions.seeTransactions || false,
            seeUserData: editFields?.permissions.seeUserData || false,
            seeVideo: editFields?.permissions.seeVideo || false,
            seeVideoAllGroups: editFields?.permissions.seeVideoAllGroups || false,
            seeVideoCompleted: editFields?.permissions.seeVideoCompleted || false,
            seeVideoNotCompleted: editFields?.permissions.seeVideoNotCompleted || false,

            setAdmin: editFields?.permissions.setAdmin || false,
            setDuelTime: editFields?.permissions.setDuelTime || false,
            setDuelType: editFields?.permissions.setDuelType || false,
            setInstruction: editFields?.permissions.setInstruction || false,
            setTasks: editFields?.permissions.setTasks || false,
            bcAdmin: editFields?.permissions.bcAdmin || false,
        },
    });

    const [updateRole] = useUpdateRoleMutation({
        refetchQueries: (data) => {
            return [
                {
                    query: ROLES,
                },
            ];
        },
    });
    const [addRole] = useAddRoleMutation({
        refetchQueries: (data) => {
            return [
                {
                    query: ROLES,
                },
            ];
        },
    });

    const submit = () => {
        errors = errorsVar([]);
        const newErrors: successErrorType[] = [];

        if ((fields?.name || "").trim().length === 0) {
            newErrors.push({
                place: "manage-role.name",
                message: "Введите имя группы",
            });
        }
        if (newErrors.length === 0) {
            editFields === null
                ? addRole({
                      variables: {
                          name: fields?.name,
                          permissions: {
                              ...fields?.permissions,
                          },
                      },
                  })
                : updateRole({
                      variables: {
                          id: fields?.id,
                          name: fields?.name,
                          permissions: {
                              ...fields?.permissions,
                          },
                      },
                  });
            roleEditVar(null);
            navigate(location.pathname + location.search);
        } else {
            errorsVar(setError(errors, newErrors));
        }
    };

    React.useEffect(() => {
        if (editFields !== null) {
            setFields({ ...editFields });
        }
    }, [editFields]);

    const title = editFields === null ? "Создать роль" : "Изменить роль";

    const nameError = errors.find((el) => el?.place === "manage-role.name");

    return (
        <div className="manage-role">
            <div className="manage-role__title">{title}</div>
            <div className="manage-role_inputContainer">
                <Input
                    title={"Название роли"}
                    value={fields?.name}
                    className={"manage-role__input"}
                    onChange={(val) => setFields({ ...fields, name: val })}
                    necessaryField={true}
                    placeholder="Введите название роли"
                    error={Boolean(nameError?.message)}
                    errorMessage={nameError?.message}
                    disabled={fields?.name === "Пользователь"}
                />
            </div>
            <ManageRolePermissions
                fields={fields}
                setFields={setFields}
            />
            <Button
                text={"Сохранить"}
                onClick={() => submit()}
                disabled={false}
                mode="red"
                className="login_btn"
            />
        </div>
    );
};

export default ManageRole;
