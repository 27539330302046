import React from "react";
import * as ReactDOM from "react-dom/client";
import { ApolloClient, ApolloProvider, createHttpLink, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { cache } from "./cache/cache";
import App from "./App";
import "./index.css";
import "./phone-input.css";
import { getAccessToken } from "./misc/common";

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    const token = getAccessToken();

    return {
        headers: {
            ...headers,
            authorization: token !== null ? token : "",
        },
    };
});

const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: cache,
});

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <React.Suspense fallback={<div className="preloader"></div>}>
                <App />
            </React.Suspense>
        </ApolloProvider>
    </React.StrictMode>,
);
